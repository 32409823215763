<template>
    <slot />
    <v-dialog v-model="dialog" width="auto" :min-width="isMobile ? undefined : 450" min-height="200" data-id="errorDialog">
        <v-card rounded="lg">
            <v-card-text class="pb-0">
                <div class="d-flex align-center mb-6">
                    <v-icon icon="mdi-alert-circle" color="error" size="32" />
                    <div class="text-h2 ml-3">{{ t('common.resources.errorDialog.title', 'System error') }}</div>
                </div>

                <div class="text-h6">
                    {{ errorMessage }}
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pt-6 pb-4 px-6">
                <v-btn variant="text" :color="showRefreshButton ? undefined : 'primary'" @click="dialog = false">
                    {{ t('common.resources.errorDialog.close', 'Close') }}
                </v-btn>
                <v-btn v-if="showRefreshButton" color="primary" variant="flat" @click="refresh">
                    {{ t('common.resources.errorDialog.reload', 'Reload') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, nextTick, onErrorCaptured, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMainStore } from '@/features/common/stores';
import { useDisplayHelpers } from '../composables';

interface Props {
    stopPropagation?: boolean;
}

const { t, te } = useI18n();
const props = defineProps<Props>();
const dialog = ref(false);
const mainStore = useMainStore();
const { lastKnownError } = storeToRefs(mainStore);
const { isMobile } = useDisplayHelpers();

const errorsToDisplay = ['PlaceDetailsError', 'GeolocationPositionError', 'Error'];
const showRefreshButton = computed(() => !lastKnownError.value || !errorsToDisplay.includes(lastKnownError.value.name));
const errorMessage = computed(() => {
    if (lastKnownError.value && errorsToDisplay.includes(lastKnownError.value.name)) {
        // return translation if it exists
        return te(lastKnownError.value.message) ? t(lastKnownError.value.message) : lastKnownError.value.message;
    }

    // default error
    return t('common.resources.errorDialog.subtitle', 'An unexpected error has occurred, please refresh window to proceed');
});

onErrorCaptured(err => {
    // you can also  get `component`, `info` if needed - just add to the function argument list
    mainStore.registerError(err);
    return !props.stopPropagation;
});

function refresh() {
    location.reload();
}

watch(lastKnownError, value => {
    if (value) {
        nextTick(() => {
            console.error(value);
            dialog.value = true;
        });
    }
});

watch(dialog, value => {
    if (!value) {
        // wait for animation to complete before resetting value
        setTimeout(() => (lastKnownError.value = null), 200);
    }
});
</script>
