<template>
    <h1 class="text-h3 text-md-h1 text-center mt-6 mb-5 mb-md-10">
        {{ t('headings.selectProduct') }}
    </h1>

    <v-row :dense="isMobile" justify="center">
        <v-col cols="12" md="auto">
            <v-btn
                data-id="buyTicket"
                color="primary"
                block
                :size="isMobile ? 'large' : 'x-large'"
                :height="isMobile ? undefined : 72"
                :rounded="0"
                :loading="isLoadingProduct"
                :disabled="isLoadingProduct"
                class="text-h5 text-md-h4 font-weight-regular px-10"
                @click="addProduct"
            >
                <div class="py-2 py-md-5">
                    {{ t('headings.buyTicket') }}
                </div>
            </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn
                data-id="buyTravelMoney"
                color="primary"
                block
                :size="isMobile ? 'large' : 'x-large'"
                :height="isMobile ? undefined : 72"
                :rounded="0"
                :loading="isLoadingTravelMoney"
                :disabled="isLoadingTravelMoney"
                class="text-h5 text-md-h4 font-weight-regular px-10"
                @click="addTravelMoney"
            >
                <div class="py-2 py-md-5">
                    {{ t('headings.addTravelMoney') }}
                </div>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { ProductType } from '@/types/webapi';
import { setupPage } from './setupPage';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { routeNames } from '@/router';
import { i18nRoute } from '@/i18n';

const { isMobile, cartApi } = setupPage();
const { t } = useI18n();
const router = useRouter();

const isLoadingProduct = ref(false);
const isLoadingTravelMoney = ref(false);

async function addProduct() {
    try {
        isLoadingProduct.value = true;
        await cartApi.setLineItem({ productType: ProductType.Ticket });
        router.push(i18nRoute({ name: routeNames.tickets }));
    } catch (e) {
        isLoadingProduct.value = false;
        throw e;
    }
}

async function addTravelMoney() {
    try {
        isLoadingTravelMoney.value = true;
        await cartApi.setLineItem({ productType: ProductType.TravelMoney });
        router.push(i18nRoute({ name: routeNames.cards }));
    } catch (e) {
        isLoadingTravelMoney.value = false;
        throw e;
    }
}
</script>
