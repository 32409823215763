import { useDisplayHelpers } from '@/features/common/composables';
import { useContainer } from '@/plugins/inversify';
import { ICartApi, ICartApiId } from '@/services/ICartApi';
import { ILogger, ILoggerId } from '@/services/ILogger';
import { IPaymentApi, IPaymentApiId } from '@/services/IPaymentApi';
import { ITicketApi, ITicketApiId } from '@/services/ITicketApi';
import { ITravelCardsApi, ITravelCardsApiId } from '@/services/ITravelCardsApi';
import { LineItemDto, ProductType, ShoppingCartDto } from '@/types/webapi';
import { computed, ref } from 'vue';

export function setupPage() {
    const container = useContainer();
    const logger = container.get<ILogger>(ILoggerId);
    const cartApi = container.get<ICartApi>(ICartApiId);
    const travelCardsApi = container.get<ITravelCardsApi>(ITravelCardsApiId);
    const paymentApi = container.get<IPaymentApi>(IPaymentApiId);
    const ticketApi = container.get<ITicketApi>(ITicketApiId);

    const { isMobile } = useDisplayHelpers();

    const cart = ref<ShoppingCartDto>();
    const lineItem = ref<Partial<LineItemDto>>();
    const isLoadingCart = ref(false);
    const isSaving = ref(false);
    const isInitialized = ref(false);
    const cartIsValid = computed(
        () =>
            lineItem.value &&
            lineItem.value.travelCard &&
            lineItem.value.productId &&
            ((isTravelMoney.value && lineItem.value.price) || (lineItem.value.configurationId && lineItem.value.categoryId))
    );

    const cartDataInGAFormat = computed(
        () =>
            cart.value?.lineItems.map(lineItem => ({
                id: lineItem.productId,
                name: lineItem.name,
                price: lineItem.price.toFixed(2).toString(),
                quantity: lineItem.quantity
            }))
    );

    async function loadCart() {
        try {
            isLoadingCart.value = true;
            cart.value = await cartApi.getCart();
            lineItem.value = cart.value.lineItems[0];
        } catch (e) {
            logger.error(e);
            throw e;
        } finally {
            isLoadingCart.value = false;
        }
    }

    async function saveCart() {
        try {
            isSaving.value = true;
            cart.value = await cartApi.setLineItem(lineItem.value as LineItemDto);
            lineItem.value = cart.value.lineItems[0];
            isSaving.value = false;
        } catch (e) {
            isSaving.value = false;
            logger.error(e);
            throw e;
        }
    }

    const isTravelMoney = computed(() => lineItem.value?.productType === ProductType.TravelMoney);

    return {
        isMobile,
        logger,
        cart,
        cartIsValid,
        lineItem,
        isLoadingCart,
        isSaving,
        isInitialized,
        cartDataInGAFormat,
        isTravelMoney,
        cartApi,
        travelCardsApi,
        paymentApi,
        ticketApi,
        loadCart,
        saveCart
    };
}
